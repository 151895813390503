// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { doc, setDoc, getDoc } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyAIUKt34aarGrOnVa1nrX7zFpx1YgBf6u8",
  authDomain: "mails1-59f1e.firebaseapp.com",
  projectId: "mails1-59f1e",
  storageBucket: "mails1-59f1e.appspot.com",
  messagingSenderId: "238250909760",
  appId: "1:238250909760:web:d488e45c7d277d572b3fa5",
  measurementId: "G-FD3K5NH1MV"
  };
  


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);


export { db, setDoc, doc, getDoc };
