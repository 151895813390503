// src/Layout.js
import React from "react";
import green from "./Green Logistics.jpg"
import glob from "./Global Connectedness Report.jpg"

const Layout = () => {
    return (
        <div className="flex flex-col items-center">
            {/* Cards Section */}
            <div className="flex flex-wrap justify-center w-full px-4 py-8 space-x-4">
                <div className="w-full md:w-1/3 mb-8">
                    <div className="border rounded-lg overflow-hidden shadow-lg">
                        <img
                            className="w-full"
                            src={green} // Replace with actual image URL
                            alt="Green Logistics"
                        />
                        <div className="p-4">
                            <h2 className="text-xl font-semibold">Green Logistics</h2>
                            <p className="text-gray-600 mt-2">
                                Sustainable business begins with sustainable supply chains. As the pioneer of green logistics, we have an unmatched portfolio of green logistics solutions. Find out what we have to offer, why we’re committed to sustainability, and how our industry is helping deliver an even better world.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="w-full md:w-1/3 mb-8">
                    <div className="border rounded-lg overflow-hidden shadow-lg">
                        <img
                            className="w-full"
                            src={glob} // Replace with actual image URL
                            alt="GCS Global Connectedness Report"
                        />
                        <div className="p-4">
                            <h2 className="text-xl font-semibold">
                                GCS Global Connectedness Report
                            </h2>
                            <p className="text-gray-600 mt-2">
                                The GCS Global Connectedness Report 2024 reveals that globalization is at a record high – despite the pandemic and geopolitical conflicts.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <hr className="w-full border-t border-gray-300 my-8" />
            {/* Footer Section */}
            <footer className="w-full p-7">
                <div className="flex flex-col sm:flex-row sm:justify-around px-2">
                    <div className="sm:w-1/3 mb-4">
                        <h3 className="font-semibold text-red-600">Quick Links</h3>
                        <ul className="mt-2 space-y-1">
                            <li><a href="#" className="hover:underline">Customer Service</a></li>
                            <li><a href="#" className="hover:underline">Customer Portal Logins</a></li>
                            <li><a href="#" className="hover:underline">Digital Partners and Integrations</a></li>
                            <li><a href="#" className="hover:underline">Developer Portal</a></li>
                        </ul>
                    </div>
                    <div className="sm:w-1/3 mb-4">
                        <h3 className="font-semibold">Our Divisions</h3>
                        <ul className="mt-2 space-y-1">
                            <li><a href="#" className="hover:underline">GCS Express</a></li>
                            <li><a href="#" className="hover:underline">Other Global Divisions</a></li>
                        </ul>
                    </div>
                    <div className="sm:w-1/3 mb-4">
                        <h3 className="font-semibold">Company Information</h3>
                        <ul className="mt-2 space-y-1">
                            <li><a href="#" className="hover:underline">About GCS</a></li>
                            <li><a href="#" className="hover:underline">Delivered</a></li>
                            <li><a href="#" className="hover:underline">Careers</a></li>
                            <li><a href="#" className="hover:underline">Press Center</a></li>
                            <li><a href="#" className="hover:underline">Investors</a></li>
                            <li><a href="#" className="hover:underline">Sustainability</a></li>
                            <li><a href="#" className="hover:underline">Brand Partnerships</a></li>
                        </ul>
                    </div>
                </div>
            </footer>

        </div>
    );
};

export default Layout;
