import React, { useState } from "react";
import logo from  "./GCS-LOGO.png"

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isTrackDropdownOpen, setIsTrackDropdownOpen] = useState(false);
    const [isShipDropdownOpen, setIsShipDropdownOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleTrackDropdown = () => {
        setIsTrackDropdownOpen(!isTrackDropdownOpen);
    };

    const toggleShipDropdown = () => {
        setIsShipDropdownOpen(!isShipDropdownOpen);
    };

    return (
        <header>
            {/* Top Section with Gradient */}
            <div className="bg-gradient-to-r from-[#ff6666] to-yellow-100 flex justify-between items-center md:px-48 p-2 -my-2">

                <div className="flex items-center">
                    <img
                        src={logo}
                        alt="GCS Logo"
                        className="h-20 p-3 md:h-20"
                    />
                </div>
                <div className="md:hidden">
                    {/* Hamburger Menu for small screens */}
                    <button onClick={toggleMenu} className="text-red-600">
                        <svg
                            className="w-6 h-6 "
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M4 6h16M4 12h16M4 18h16"
                            ></path>
                        </svg>
                    </button>
                </div>
                <div className="hidden md:flex items-center space-x-2 md:space-x-4 text-xs md:text-sm">
                    {/* <a href="#" className="hover:underline">
                        Find a Location <span className="text-[10px] md:text-xs">⤴</span>
                    </a>
                    <a href="#" className="hover:underline">
                        🔍 Search
                    </a> */}
                </div>
            </div>

            {/* Dropdown Menu for Small Screens */}
            <div className={`bg-white ${isMenuOpen ? "block" : "hidden"} md:hidden`}>
                <div className="flex flex-col px-4 py-2 space-y-4 text-sm">
                    {/* Track Dropdown */}
                    <div>
                        <button
                            className="flex justify-between w-full"
                            onClick={toggleTrackDropdown}
                        >
                            Track
                            <span>{isTrackDropdownOpen ? "▲" : "▼"}</span>
                        </button>
                        {isTrackDropdownOpen && (
                            <ul className="ml-4 mt-2 space-y-2">
                                <li>
                                    <a href="#" className="hover:underline">
                                        Tracking Overview
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="hover:underline">
                                        Track Shipment
                                    </a>
                                </li>
                            </ul>
                        )}
                    </div>

                    {/* Ship Dropdown */}
                    <div>
                        <button
                            className="flex justify-between w-full"
                            onClick={toggleShipDropdown}
                        >
                            Ship
                            <span>{isShipDropdownOpen ? "▲" : "▼"}</span>
                        </button>
                        {isShipDropdownOpen && (
                            <ul className="ml-4 mt-2 space-y-2">
                                <li>
                                    <a href="#" className="hover:underline">
                                        Shipping Overview
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="hover:underline">
                                        Get a Quote
                                    </a>
                                </li>
                            </ul>
                        )}
                    </div>

                    {/* Other Menu Items */}
                    <a href="#" className="hover:underline">
                        Customer Service
                    </a>
                    <a href="#" className="flex items-center hover:underline">
                        <span role="img" aria-label="user">
                            👤
                        </span>
                        Customer Portal Logins <span className="ml-1">⤴</span>
                    </a>
                    <a href="#" className="hover:underline">
                        Find a Location <span className="text-xs">⤴</span>
                    </a>
                    <a href="#" className="hover:underline">
                        🔍 Search
                    </a>
                    <a href="#" className="flex items-center hover:underline">
                        <span role="img" aria-label="flag">
                            🇳🇬
                        </span>
                        Change Location (NG)
                    </a>
                </div>
            </div>

            {/* Bottom Section for larger screens */}
            <div className="hidden md:block bg-white">
                <div className="flex justify-between items-center px-24 lg:px-52 py-4 text-sm">
                    <div className="flex space-x-6">
                        <a href="#" className="hover:underline">
                            Track <span className="text-xs">▼</span>
                        </a>
                        <a href="#" className="hover:underline">
                            Ship <span className="text-xs">▼</span>
                        </a>
                        <a href="#" className="hover:underline">
                            Customer Service
                        </a>
                    </div>
                    <a href="#" className="hover:underline">
                        Customer Portal Logins <span className="text-xs">▼</span>
                    </a>
                </div>
            </div>
        </header>
    );
};

export default Navbar;
