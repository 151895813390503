import React, { useState } from 'react';
import { db, doc, getDoc } from '../firebaseConfig';
import Receipt from './Receipt';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const HeroSection = () => {
  const [waybill, setwaybill] = useState('');
  const [shippingData, setShippingData] = useState(null);
  const [error, setError] = useState('');
  const [isTracking, setIsTracking] = useState(false);

  const handleInputChange = (e) => {
    setwaybill(e.target.value);
  };

  const fetchData = async () => {
    setError('');
    setShippingData(null);
    setIsTracking(true); // Show the modal

    try {
      const docRef = doc(db, 'shippingForms', waybill);
      const snapshot = await getDoc(docRef);

      if (snapshot.exists()) {
        setShippingData(snapshot.data());
      } else {
        setError('No data found for the provided Waybill ID.');
        setIsTracking(false); // Reset modal visibility if no data is found
      }
    } catch (err) {
      setError('Error fetching data: ' + err.message);
      setIsTracking(false); // Reset modal visibility on error
    }
  };

  // Close the receipt modal
  const handleCancel = () => {
    setIsTracking(false);
    setShippingData(null);
  };

  // Download the receipt as a PDF
  const handleDownload = () => {
    const receiptElement = document.getElementById('receipt'); // The receipt container

    html2canvas(receiptElement).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      pdf.addImage(imgData, 'PNG', 0, 0);
      pdf.save('receipt.pdf');
    });
  };

  return (
    <section
      className="relative bg-cover bg-center h-screen md:h-96 lg:px-8 lg:py-12"
      style={{
        backgroundImage: "url('https://firebasestorage.googleapis.com/v0/b/hddd-15477.appspot.com/o/bbb.jpg?alt=media&token=36f90b9f-3d7b-422f-891a-c7da1f3bcf5f')",
        // backgroundSize: 'con',
        backgroundPosition: 'center',
      }}
    >
      {/* Gradient Overlay from Bottom */}
      <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent"></div>

      {/* Main content that will become faint when tracking */}
      <div className={`relative flex flex-col items-center justify-center h-full text-white px-4 md:px-8 ${shippingData ? 'pointer-events-none opacity-30' : ''}`}>
        {/* Title */}
        <h1 className="text-sm mr-52 md:text-4xl lg:text-3xl mt-28 lg:mt-40 font-bold mb-4 lg:ml-[31%] lg:self-start">
          Track Your Shipment
        </h1>

        <div className="relative w-full max-w-xl mb-8 flex flex-col md:flex-row items-center">
          <div className="relative w-full md:flex-grow">
            <input
              value={waybill}
              onChange={handleInputChange}
              type="text"
              id="trackingNumber"
              className="block px-3 py-3 w-full text-gray-900 bg-white border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-1 focus:ring-red-100 peer"
              placeholder=" "
              required
            />
            <label
              htmlFor="trackingNumber"
              className="absolute left-3 top-3 text-gray-500 duration-300 transform -translate-y-4 scale-90 origin-[0] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-4"
            >
              Enter your tracking number(s)
            </label>
          </div>

          <button
            onClick={fetchData}
            type="submit"
            className="mt-4 md:mt-0 md:ml-4 bg-red-600 hover:bg-red-700 px-4 py-3 rounded-md text-white font-bold w-full md:w-auto transition-colors duration-200 ease-in-out"
          >
            Track
          </button>
        </div>
      </div>

      {/* Conditionally render the modal with receipt */}
      {isTracking && shippingData && (
        <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
          <div id="receipt" className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full relative">
            <Receipt shippingData={shippingData} />

            {/* Action buttons */}
            <div className="flex justify-between mt-4">
              <button
                onClick={handleCancel}
                className="bg-gray-600 hover:bg-gray-700 text-white px-4 py-2 rounded-md"
              >
                Close
              </button>
              <button
                onClick={handleDownload}
                className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md"
              >
                Download
              </button>
              <button className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md"
              > Status</button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default HeroSection;
