import React, { useEffect, useRef, useState } from 'react';
import { db, doc, setDoc } from '../firebaseConfig'; // Adjust the import based on your file structure

const ShippingForm = () => {
  const [formData, setFormData] = useState({
    from: '',
    to: '',
    origin: '',
    contact: '',
    ref: '',
    pceShptWeight: '',
    piece: '',
    contents: '',
    waybillNo: '',
  });

  const [savedId, setSavedId] = useState(null);
  const fromInputRef = useRef(null);
  const toInputRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Save data to Firestore
    try {
      const formRef = doc(db, 'shippingForms', formData.waybillNo);
      await setDoc(formRef, formData);
      console.log('Data saved successfully:', formData);
      
      setSavedId(formData.waybillNo);
      setFormData({
        from: '',
        to: '',
        origin: '',
        contact: '',
        ref: '',
        pceShptWeight: '',
        piece: '',
        contents: '',
        waybillNo: '',
      });
    } catch (error) {
      console.error('Error saving data to Firestore:', error);
    }
  };

  useEffect(() => {
    const loadAutocomplete = () => {
      if (window.google) {
        const fromAutocomplete = new window.google.maps.places.Autocomplete(fromInputRef.current);
        const toAutocomplete = new window.google.maps.places.Autocomplete(toInputRef.current);

        fromAutocomplete.addListener('place_changed', () => {
          const place = fromAutocomplete.getPlace();
          if (place && place.formatted_address) {
            setFormData((prevData) => ({
              ...prevData,
              from: place.formatted_address,
            }));
          }
        });

        toAutocomplete.addListener('place_changed', () => {
          const place = toAutocomplete.getPlace();
          if (place && place.formatted_address) {
            setFormData((prevData) => ({
              ...prevData,
              to: place.formatted_address,
            }));
          }
        });
      } else {
        console.error('Google Maps JavaScript API not loaded');
      }
    };

    const intervalId = setInterval(() => {
      if (window.google) {
        loadAutocomplete();
        clearInterval(intervalId);
      }
    }, 100);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="max-w-2xl mx-auto mt-4 p-6 bg-white rounded shadow-md">
      <h2 className="text-2xl font-semibold mb-6">Shipping Form</h2>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          {/* Form Fields */}
          {Object.entries(formData).map(([key, value]) => (
            <div key={key}>
              <label htmlFor={key} className="block text-sm font-medium text-gray-700">
                {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}
              </label>
              <input
                type={key === 'contents' ? 'textarea' : 'text'}
                id={key}
                name={key}
                ref={key === 'from' ? fromInputRef : key === 'to' ? toInputRef : null}
                value={value}
                onChange={handleChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                required
              />
            </div>
          ))}
        </div>

        <div className="mt-6">
          <button
            type="submit"
            className="w-full bg-indigo-500 text-white py-2 px-4 rounded-md hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
          >
            Submit
          </button>
        </div>
      </form>

      {savedId && (
        <div className="mt-4 p-4 bg-green-100 text-green-800 rounded">
          Data saved successfully! Your Waybill ID is: {savedId}
        </div>
      )}
    </div>
  );
};

export default ShippingForm;
