import React from 'react';
import Barcode from 'react-barcode';
import { MdDoubleArrow } from "react-icons/md";
import logo from "./GCS-LOGO.png"  

const Receipt = ({ shippingData }) => {
  return (
    <div className="receipt border p-4 rounded shadow-md bg-white">
      <div className='flex my-6 justify-start border-b-2 p-2 items-center text-center gap-5'>
      <img className='w-28' src={logo}/>
      <h2 className="text-md font-bold mb-4">GCDISPATCHER </h2>
      </div>

      <div className="justify-between mb-4">
        {/* Origin and Contact */}
        <div>
          <p className="font-semibold">Shiping From:</p>
          <p>From  {shippingData.from_name} </p>
          <p>{shippingData.from}</p>
          <p>Contact: <br /> {shippingData.sender_email}</p>
        </div>

        <div className='my-6'>
          <p className="font-semibold">To:</p>  
          <p>To: {shippingData.to_name}</p> 
          <p>{shippingData.toAddress}</p>
          <p>Contact: {shippingData.receiver_email}</p> 
        </div>
      </div>

      <div className="mb-4">
        <h3 className="font-semibold">Tracking ID:</h3>
        <p>{shippingData.waybillNo}</p>
      </div>

      {/* Shipping Details */}
      <div className="mb-4 flex justify-between">
       
        <div className='text-center'>
        {/* <MdDoubleArrow size={29} /> */}
          <p className="font-semibold">Order ID:</p>
          <p>{shippingData.orderID}</p>
        </div>

        <div className='text-center'>
        {/* <MdDoubleArrow size={29} /> */}
          <p className="font-semibold">Current Location:</p>
          <p>{shippingData.currentLocation} </p>
        </div>

        <div className='text-center'>
         {/* <MdDoubleArrow color={shippingData.status === 'pending' ? "gray" : "blue"} size={29} /> */}
          <p className="font-semibold">Status</p>
          <p>{shippingData.status}</p>
        </div>
      </div>

      <div className="mb-4">
        
        <h3 className="font-semibold">Contents:</h3>
        <p>{shippingData.contents}</p>
      </div>

      <div className="mb-4">
        <h3 className="font-semibold">Recieved By:</h3>
        <p>{shippingData.recieverName}</p>
      </div>

      {/* <div className="mb-4">
        <h3 className="font-semibold">Account No:</h3>
        <p>{shippingData.accountNo}</p>
      </div> */}

      {/* Barcode */}
      <div className="barcode mb-4">
        <Barcode value={shippingData.waybillNo} />
      </div>

      <div className="text-right">
        <p className="font-semibold">Thank you for shipping with us!</p>
      </div>
    </div>
  );
};

export default Receipt;
